<template>
  <div id="app">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-dark" :style="{ backgroundColor: 'var(--primary-color)' }">
      <div class="container-fluid">
        <!-- Logo -->
        <router-link to="/" class="navbar-brand">
          <img src="@/assets/logo2.png" alt="Pizzeria Treviglio" class="logo">
        </router-link>

        <!-- Menu burger (responsive) -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar content -->
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-lg-center">
            <li class="nav-item">
              <router-link to="/" exact class="nav-link" @click="closeMenu">
                <i class="fas fa-home"></i> Accueil
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/menu" class="nav-link" @click="closeMenu">
                <i class="fas fa-utensils"></i> Notre Carte
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link" @click="closeMenu">
                <i class="fas fa-info-circle"></i> À propos
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link" @click="closeMenu">
                <i class="fas fa-envelope"></i> Contact
              </router-link>
            </li>
            <!-- Bouton d'appel -->
            <li class="nav-item">
              <a href="tel:+3281570790" class="btn btn-secondary nav-button ms-lg-3 mt-2 mt-lg-0">
                <i class="fas fa-phone-alt"></i> Commander ou Réserver
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Contenu principal -->
    <div class="content">
      <router-view />
    </div>

    <!-- Composant de consentement aux cookies -->
    <CookieConsent />

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-info">
        <p>Rue Georges Emmanuel 3, 5020 Flawinne</p>
        <p>+32 81 570 790 | +32 493 762 927</p>
        <p>info@pizzeriatreviglio.be</p>
        <router-link to="/contact" @click="closeMenu" class="contact-link">
          <span class="footer-links">Besoin d'informations ? Contactez-nous !</span>
        </router-link>
        <div class="button-container mb-3">
          <button class="nav-button btn btn-sm btn-secondary" @click="openGoogleMaps">
            <i class="fas fa-map-marker-alt"></i> Google Maps
          </button>
        </div>
        <hr class="footer">
        <!-- Bouton de partage Facebook -->
        <div class="share-section">
          <p class="share-text">Partagez votre expérience avec vos amis sur Facebook !</p>
          <div class="fb-share-button mb-4"
               data-href="https://pizzeriatreviglio.be"
               data-layout="button"
               data-size="large">
          </div>
        </div>
        <hr class="footer">
      </div>
      <div class="footer-links">
        <router-link :to="{ name: 'PrivacyPolicyPage' }">Politique de Confidentialité</router-link>
        <span> | </span>
        <router-link :to="{ name: 'LegalNoticePage' }">Mentions Légales</router-link>
      </div>
      <div class="footer-copyright">
        <p>&copy; 2024 Pizzeria Treviglio. Tous droits réservés.</p>
        <p>Made with <span class="heart">&hearts;</span> by <a href="https://cyberglow.be" target="_blank">CyberGlow</a></p>
      </div>
    </footer>
  </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';
import { Collapse } from 'bootstrap';

export default {
  name: 'App',
  components: {
    CookieConsent,
  },
  methods: {
    closeMenu() {
      // Ferme le menu burger après un clic sur un lien
      const navbarCollapse = document.getElementById('navbarNavDropdown');
      if (navbarCollapse && navbarCollapse.classList.contains('show')) {
        const bsCollapse = Collapse.getInstance(navbarCollapse);
        if (bsCollapse) {
          bsCollapse.hide();
        } else {
          new Collapse(navbarCollapse).hide();
        }
      }
    },
    openGoogleMaps() {
      const lat = 50.46071484267182;
      const lng = 4.797822742580121;
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
@import './assets/custom-styles.css';

/* Styles pour la navbar */
.navbar-brand .logo {
  height: 50px;
  width: auto;
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.nav-link i {
  color: #fff;
}

.navbar-nav .nav-link:hover {
  color: var(--secondary-color);
}

.navbar-nav .nav-link:hover i {
  color: var(--secondary-color);
}

.navbar-nav .nav-item .btn {
  padding: 0.5em 1em;
  font-size: 0.9rem;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-button {
  margin-left: 0;
}

@media (min-width: 992px) {
  .nav-button {
    margin-left: 15px;
  }
}

/* Ajout du fond coloré et de la largeur 100% au menu déroulant sur mobile */
@media (max-width: 991px) {
  .navbar-collapse {
    background-color: var(--primary-color);
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
  }

  .navbar-nav {
    width: 100%;
    text-align: center;
  }

  .navbar-nav .nav-item {
    width: 100%;
  }

  .navbar-nav .nav-link {
    padding: 0.5rem 1rem;
  }

  .nav-button {
    width: 90%;
    margin: 10px auto;
  }
}

/* Footer Styles */
.footer {
  background-color: var(--primary-color);
  color: #fff;
  padding: 20px 10px;
  text-align: center;
  font-family: 'Merriweather', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
}

.footer-info,
.footer-links {
  width: 100%;
}

.footer-info p {
  margin: 5px 0;
  font-size: 1.2rem;
}

.contact-link {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s ease;
  margin-top: 10px;
}

.contact-link:hover {
  color: var(--secondary-color);
}

.footer hr {
  width: 50%;
  border: none;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  margin: 5px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
}

.footer-links {
  font-size: 0.9rem;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  color: var(--secondary-color);
}

.footer-links span {
  color: #fff;
}

.button-container {
  margin-top: 10px;
}

.nav-button.btn-sm {
  padding: 0.25em 0.5em;
  font-size: 0.8rem;
}

.share-section {
  text-align: center;
}

.share-text {
  margin-bottom: 10px;
}

.fb-share-button {
  margin-bottom: 20px;
}

.footer .heart {
  color: red;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: var(--secondary-color);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
  }

  .footer-info,
  .footer-links {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px 10px;
  }

  .footer-info p {
    font-size: 1rem;
  }

  .footer-links {
    font-size: 0.8rem;
  }
}
</style>
